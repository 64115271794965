<template>
  <a-badge-ribbon :text="`Top ${index + 1}`" placement="end" :color="coinColor(index)" >
    <a-card style="width: 100%" class="profit-card">
      <a-row :gutter="24">

        <a-col :xs="24" :sm="24" :md="6" :lg="6">
          <a-space align="start">
            <a-avatar :src="`/coins/${coin.id}.webp`" />
            <a-space direction="vertical" :size="0">
              <a-typography-title :level="5" v-text="coinName"></a-typography-title>
              <a-typography-text type="secondary" v-text="`Algo: ${coin.algorithm}`" class="algo"></a-typography-text>
            </a-space>
          </a-space>
        </a-col>

        <a-col :xs="24" :sm="24" :md="8" :lg="8">
          <table>
            <tbody>
              <tr>
                <td>Block Time</td>
                <td>:</td>
                <td>{{ coin.block_time }}s</td>
              </tr>
              <tr>
                <td>Block Reward</td>
                <td>:</td>
                <td>{{ `${round(coin.block_reward)} ${coin.tag}` }}</td>
              </tr>
              <tr>
                <td>Market Cap</td>
                <td>:</td>
                <td>{{ coin.market_cap }}</td>
              </tr>
              <tr>
                <td>Net Hasrate</td>
                <td>:</td>
                <td>{{ formatHashrate(coin.nethash) }}</td>
              </tr>
            </tbody>
          </table>
        </a-col>

        <a-col :xs="24" :sm="24" :md="10" :lg="10">
          <table>
            <tbody>
              <tr>
                <td>Hashrate</td>
                <td>:</td>
                <td>{{ template.hash }} {{ template.unit }}</td>
              </tr>
              <tr>
                <td>Estimated</td>
                <td>:</td>
                <td>{{ `${rewards} ${coin.tag}` }}</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>:</td>
                <td>${{ price }}</td>
              </tr>
              <tr>
                <td>Profit</td>
                <td>:</td>
                <td>${{ profit }}</td>
              </tr>
            </tbody>
          </table>
        </a-col>

      </a-row>
    </a-card>
  </a-badge-ribbon>
</template>
<script>
import emitter from '@/apis/events';
export default {
  name: 'ProfitCard',
  props: ['index', 'coin', 'template'],
  data: () => {
    return {
      btc_price: 0
    }
  },
  computed: {
    coinName() {
      return this.coin.name + ` (${this.coin.tag})`
    },
    price() {
      return this.round(this.coin.exchange_rate * this.btc_price)
    },
    profit() {
      const rewards = this.coin.estimated_rewards.replace(',', '');
      return this.round(Number(this.price) * Number(rewards));
    },
    rewards() {
      const rewards = this.coin.estimated_rewards.replace(',', '');
      return this.round(rewards);
    }
  },
  mounted() {
    emitter.on('btc_price', (price) => {
      this.btc_price = price;
    })
  },
  methods: {
    round(number) {
      const str = number.toString();
      const decimalIndex = str.indexOf('.');
      if (decimalIndex === -1) return number;
      const firstNonZeroIndex = str.slice(decimalIndex + 1).search(/[1-9]/);
      const roundPosition = decimalIndex + firstNonZeroIndex + 3;
      return Math.round(number * Math.pow(10, roundPosition - decimalIndex - 1)) / Math.pow(10, roundPosition - decimalIndex - 1);
    },
    formatHashrate(hashrate) {
      const units = ['H/s', 'KH/s', 'MH/s', 'GH/s', 'TH/s', 'PH/s', 'EH/s'];
      let unitIndex = 0;

      while (hashrate >= 1000 && unitIndex < units.length - 1) {
          hashrate /= 1000;
          unitIndex++;
      }

      return `${hashrate.toFixed(2)} ${units[unitIndex]}`;
    },
    coinColor: (i) => {
      switch (i) {
        case 0:
          return 'red'
        case 1:
          return '#dbc00e'
        case 2:
          return '#da6d00'
        default:
          return 'blue'
      }
    }
  }
};
</script>
<style scoped>
  h5 {
    margin-bottom: 0;
  }

  .algo {
    font-size: 12px;
  }

  .profit-card {
    margin-bottom: 1rem;
  }

  table td {
    padding-left: 5px;
    padding-right: 5px;
  }

  table td:first-child {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }

  table td:last-child {
    color: #00961a;
  }
</style>
