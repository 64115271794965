import { createApp } from 'vue'
import App from './App.vue'
import VueParticles from 'vue-particles'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { createWebHistory, createRouter } from 'vue-router'

import HomePage from './pages/HomePage.vue';
import BitcoinPuzzle from './pages/BitcoinPuzzle.vue';

const routes = [
  { path: '/', component: HomePage, name: 'profit' },
  { path: '/btc-puzzle', component: BitcoinPuzzle, name: 'puzzle' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

createApp(App)
.use(VueParticles)
.use(Antd)
.use(router)
.mount('#app')
