<template>
  <a-config-provider :theme="theme">
    <div class="layout">
      <vue-particles class="background" color="#f7931a" :particleOpacity="0.7" :particlesNumber="110" shapeType="circle"
        :particleSize="5" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="5"
        :hoverEffect="true" hoverMode="grab" :clickEffect="false" clickMode="push"></vue-particles>

      <a-layout class="body">
        <a-layout-header class="header">
          <div class="logo">
            <img src="/logo.png" alt="logo" />
          </div>

          <a-button type="ghost" @click="showDrawer" style="color: white;font-size: 1.4rem;padding:0;">
            <MenuOutlined />
          </a-button>
          <a-drawer v-model:open="open" class="mobile-menu" title="Menu" placement="right">
            <template #extra>
              <div class="btc-price">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
                  width="100%" height="100%" version="1.1" shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                  clip-rule="evenodd" viewBox="0 0 4091.27 4091.73">
                  <g id="Layer_x0020_1">
                    <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                    <g id="_1421344023328">
                      <path fill="#F7931A" fill-rule="nonzero"
                        d="M4030.06 2540.77c-273.24,1096.01 -1383.32,1763.02 -2479.46,1489.71 -1095.68,-273.24 -1762.69,-1383.39 -1489.33,-2479.31 273.12,-1096.13 1383.2,-1763.19 2479,-1489.95 1096.06,273.24 1763.03,1383.51 1489.76,2479.57l0.02 -0.02z">
                      </path>
                      <path fill="white" fill-rule="nonzero"
                        d="M2947.77 1754.38c40.72,-272.26 -166.56,-418.61 -450,-516.24l91.95 -368.8 -224.5 -55.94 -89.51 359.09c-59.02,-14.72 -119.63,-28.59 -179.87,-42.34l90.16 -361.46 -224.36 -55.94 -92 368.68c-48.84,-11.12 -96.81,-22.11 -143.35,-33.69l0.26 -1.16 -309.59 -77.31 -59.72 239.78c0,0 166.56,38.18 163.05,40.53 90.91,22.69 107.35,82.87 104.62,130.57l-104.74 420.15c6.26,1.59 14.38,3.89 23.34,7.49 -7.49,-1.86 -15.46,-3.89 -23.73,-5.87l-146.81 588.57c-11.11,27.62 -39.31,69.07 -102.87,53.33 2.25,3.26 -163.17,-40.72 -163.17,-40.72l-111.46 256.98 292.15 72.83c54.35,13.63 107.61,27.89 160.06,41.3l-92.9 373.03 224.24 55.94 92 -369.07c61.26,16.63 120.71,31.97 178.91,46.43l-91.69 367.33 224.51 55.94 92.89 -372.33c382.82,72.45 670.67,43.24 791.83,-303.02 97.63,-278.78 -4.86,-439.58 -206.26,-544.44 146.69,-33.83 257.18,-130.31 286.64,-329.61l-0.07 -0.05zm-512.93 719.26c-69.38,278.78 -538.76,128.08 -690.94,90.29l123.28 -494.2c152.17,37.99 640.17,113.17 567.67,403.91zm69.43 -723.3c-63.29,253.58 -453.96,124.75 -580.69,93.16l111.77 -448.21c126.73,31.59 534.85,90.55 468.94,355.05l-0.02 0z">
                      </path>
                    </g>
                  </g>
                </svg>
                <span>${{ btcprice }}</span>
              </div>
            </template>

            <a-menu mode="vertical" class="menu" v-model:selectedKeys="menu" :inlineCollapsed="false"
              @click="onMenuChange($event)" :style="{ minWidth: '400px', justifyContent: 'center' }">
              <a-menu-item key="profit" path="">
                <HomeOutlined /> Profit
              </a-menu-item>
              <a-menu-item key="puzzle" path="btc-puzzle">
                <BulbOutlined /> Puzzles
              </a-menu-item>
              <!-- <a-menu-item key="scan" path="scan">
                <SearchOutlined /> Scan
              </a-menu-item>
              <a-menu-item key="tools" path="tools">
                <ToolOutlined /> Tools
              </a-menu-item> -->
            </a-menu>
          </a-drawer>
        </a-layout-header>

        <a-layout-content class="content">
          <RouterView />
        </a-layout-content>
      </a-layout>
    </div>
  </a-config-provider>
</template>

<script>
/* eslint-disable */
import { HomeOutlined, BulbOutlined, SearchOutlined, ToolOutlined, MenuOutlined } from '@ant-design/icons-vue';
import emitter from './apis/events';
export default {
  name: 'App',
  components: { HomeOutlined, BulbOutlined, SearchOutlined, ToolOutlined, MenuOutlined },
  data: () => ({
    btcprice: 0,
    open: false,
    menu: ['profit'],
    theme: {
      token: {
        fontFamily: "Russo One"
      }
    }
  }),
  mounted() {
    const stream = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@miniTicker');
    stream.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        emitter.emit('btc_price', data.c);
        this.btcprice = Number(data.c).toFixed(1);
      } catch (error) {
        console.log('[Error]: ', error.message);
      }
    }

    this.$router.beforeEach(async (to) => {
      this.menu = [to.name]
    })
  },
  methods: {
    showDrawer() {
      this.open = !this.open;
    },
    onMenuChange(e) {
      this.$router.push(`/${e.item.path}`);
      this.open = false;
    }
  }
}
</script>

<style scoped>
.menu {
  background: transparent;
  line-height: 40px !important;
}

.menu .ant-menu-item {
  color: #fff !important;
}
</style>

<style>
.ant-drawer .ant-drawer-body {
  padding: 0;
  padding-top: 1rem;
}

.ant-drawer .ant-drawer-header {
  padding-left: 14px;
}

.layout {
  width: 100%;
  height: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 0 1rem;
}

.body {
  position: relative;
  z-index: 2;
  background: transparent;
}

.header {
  background: transparent !important;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0 !important;
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #111;
}

.logo img {
  max-height: 40px;
}

.btc-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #000;
  border-radius: 40px;
  background: #f5f5f5;
  padding: 0px 12px 0px 0;
  line-height: 1;
  font-size: 14px;
  width: 122px;
}

.btc-price svg {
  height: 35px;
  width: 35px;
  min-width: 35px;
}

.content {
  padding: 2rem 0;
}
</style>
