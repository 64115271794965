<template>
  <div class="home">
    <div class="page-header">
      <a-typography-title :level="2" class="title">CPU Mining Profit</a-typography-title>
      <span class="desc">Mining profit for CPU Mining (Calculated with 1 vps 4 CPUs).</span>
    </div>

    <div class="home-content">
      <a-spin :spinning="loading">
        <a-empty v-if="profits.length == 0" class="loading" />
        <div class="profits" v-else>
          <a-row :gutter="24">
            <a-col :span="24" v-for="(item, index) in profits" :key="item.id">
              <ProfitCard :coin="item" :index="index" :template="getTemplate(item.id)" />
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getCoinInfos } from '@/apis/coins';
import ProfitCard from '@/components/home/ProfitCard.vue';

export default {
  name: 'HomePage',
  components: {
    ProfitCard
  },
  data: () => ({
    loading: true,
    coins: [
      { id: '333', coin: 'Verus', tag: 'VRSC', algorithm: 'VerusHash', hash: 4.5, unit: 'Mh/s' },
      { id: '371', coin: 'Zephyr', tag: 'ZEPH', algorithm: 'RandomX', hash: 1.4, unit: 'Kh/s' },
      { id: '424', coin: 'PepePow', tag: 'PEPEW', algorithm: 'Xelishashv2', hash: 2.0, unit: 'Kh/s' },
      { id: '415', coin: 'Kylacoin', tag: 'KCN', algorithm: 'Flex', hash: 0.35, unit: 'Kh/s' },
      { id: '347', coin: 'Raptoreum', tag: 'RTM', algorithm: 'Ghostrider', hash: 0.4, unit: 'Kh/s' },
      { id: '101', coin: 'Monero', tag: 'XMR', algorithm: 'RandomX', hash: 1.4, unit: 'Kh/s' },
      { id: '427', coin: 'Salvium', tag: 'SAL', algorithm: 'RandomX', hash: 1.4, unit: 'Kh/s' },
      { id: '263', coin: 'Quantum R L', tag: 'QRL', algorithm: 'RandomX', hash: 1.4, unit: 'Kh/s' }
    ],
    profits: []
  }),
  mounted() {
    this.getInfos();
  },
  methods: {
    compareProfit(a, b) {
      const pa = Number(a.profit.replace('$', ''));
      const pb = Number(b.profit.replace('$', ''));
      return pb - pa;
    },
    getTemplate(id) {
      return this.coins.find(o => o.id == id);
    },
    getInfos() {
      this.loading = true;
      const items = this.coins.map((coin) => ({
        id: coin.id,
        params: { hr: coin.hash, p: '0.0', fee: '0.0', cost: '0.0', cost_currency: 'USD', hcost: '0.0'}
      }));

      getCoinInfos(items)
      .then((data) => {
        this.profits = data.sort((a, b) => this.compareProfit(a, b));
      })
      .catch((err) => {
        console.log('[Error]: ', err.message);
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.page-header {
  text-align: center;
}

.title {
  color: #fff;
  margin-bottom: 0;
  line-height: 1.5;
  letter-spacing: 1px;
}

.desc {
  font-size: 11px;
  color: rgb(255, 221, 0);
  letter-spacing: 1px;
  font-weight: 100;
}

.home-content {
  margin-top: 2rem;
}

.loading {
  color: #fff;
  padding: 1rem;
}
</style>