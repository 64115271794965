<template>
  <div class="puzzle">
    <div class="page-header">
      <a-typography-title :level="2" class="title">Bitcoin Puzzle Hunter</a-typography-title>
      <span class="desc">Engage with challenging Bitcoin puzzles and earn BTC rewards by solving them in Visual Puzzle Hunter..</span>
    </div>

    <div class="body">
      <a-row :gutter="16">
        <a-col class="gutter-row mb-1" :xs="12" :sm="12" :md="6" :xl="6">
          <a-card class="card-status" :bordered="false" :body-style="{ padding: '1rem' }">
            <span>Solved Puzzle</span>
            <p>{{ statistic.solved }}/{{ statistic.total }}</p>
          </a-card>
        </a-col>
        <a-col class="gutter-row mb-1" :xs="12" :sm="12" :md="6" :xl="6">
          <a-card class="card-status" :bordered="false" :body-style="{ padding: '1rem' }">
            <span>Total Rewards</span>
            <p>{{ this.infomation.total }} BTC</p>
          </a-card>
        </a-col>
        <a-col class="gutter-row mb-1" :xs="12" :sm="12" :md="6" :xl="6">
          <a-card class="card-status" :bordered="false" :body-style="{ padding: '1rem' }">
            <span>Unsolved Reward</span>
            <p>{{ this.infomation.remain }} BTC</p>
          </a-card>
        </a-col>
        <a-col class="gutter-row mb-1" :xs="12" :sm="12" :md="6" :xl="6">
          <a-card class="card-status" :bordered="false" :body-style="{ padding: '1rem' }">
            <span>Solved Reward</span>
            <p>{{ this.infomation.won }} BTC</p>
          </a-card>
        </a-col>
      </a-row>


      <a-card class="card-status mt-3" :bordered="false" :body-style="{ padding: '1rem' }">
        <div class="worker-form">
          <a-select v-model:value="itemId" :options="options" placeholder="Please select" style="width: 50%;"
            :disabled="status"></a-select>
          <a-input-number v-model:value="threads" placeholder="Threads" style="width: 30%;" :min="1"
            :disabled="status" />
          <a-button class="start-button" style="width: 20%;" v-if="!status" @click.prevent="onStart()">Start</a-button>
          <a-button type="primary" danger style="width: 20%;" v-else @click.prevent="onStop()">Stop</a-button>
        </div>

        <table class="info-table" v-if="item">
          <tbody>
            <tr>
              <td>Puzzle</td>
              <td>#{{ item.id }}</td>
            </tr>
            <tr>
              <td>Reward</td>
              <td>{{ item.balance }} BTC</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ item.solved ? 'Solved' : 'Unsolved...' }}</td>
            </tr>
            <tr>
              <td>Start</td>
              <td>{{ range[0] }}</td>
            </tr>

            <tr>
              <td>End</td>
              <td>{{ range[1] }}</td>
            </tr>
            <tr>
              <td>Target</td>
              <td>{{ item.target }}</td>
            </tr>
            <tr>
              <td>Hashrate</td>
              <td class="info">{{ hashrate.toLocaleString('en-US') }} keys/s</td>
            </tr>
            <tr>
              <td>Total Scanned</td>
              <td class="info">{{ total.toLocaleString('en-US') }} keys</td>
            </tr>
            <tr>
              <td>Last Key</td>
              <td class="info">{{ lastKey }}</td>
            </tr>
          </tbody>
        </table>
      </a-card>
    </div>

    <hr />

    <div class="page-header mt-3">
      <a-typography-title :level="2" class="title">Your Board</a-typography-title>
    </div>

    <a-card class="card-status" :bordered="false" :body-style="{ padding: '1rem' }">
    <table class="result-table">
      <thead>
        <tr>
          <th>Puzzle</th>
          <th>Target</th>
          <th>Private Key</th>
          <th>Total Scanned</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="solved.length == 0">
          <td colspan="4" style="text-align: center;">
            <a-empty></a-empty>
          </td>
        </tr>
        <tr v-else v-for="item in solved" :key="item.id">
          <td>#{{ item.id }}</td>
          <td>{{ item.target }}</td>
          <td>{{ item.privateKey }}</td>
          <td>{{ item.totalScanned.toLocaleString('en-US') }} keys</td>
        </tr>
      </tbody>
    </table>
  </a-card>
  </div>
</template>
<script>
import { message } from 'ant-design-vue';

export default {
  name: 'BitcoinPuzzle',
  computed: {
    statistic() {
      return {
        solved: this.infomation.items.filter(o => o.solved).length,
        total: this.infomation.items.length
      };
    },
    options() {
      return [...this.infomation.items]  // Spread to create a shallow copy
        .sort((a, b) => {
          if (a.solved === b.solved) return 0;
          return a.solved ? -1 : 1;
        })
        .map((item) => ({
          value: Number(item.id),
          label: `Puzzle ${item.id} - ${item.solved ? 'Solved' : 'Waiting'} - ${item.balance} BTC`
        }));
    },
    item() {
      const id = this.itemId;
      return this.infomation.items.find(o => o.id == id);
    },
    range() {
      if (!this.item) return ["1", "1"];
      const [start, end] = this.item.range.split(':');
      return [start.padStart(64, '0'), end.padStart(64, '0')]
    },
    hashrate() {
      return this.speeds.reduce((a, b) => a + b, 0);
    }
  },
  data() {
    return {
      infomation: {
        total: 988.498,
        won: 51.598,
        remain: 936.9,
        items: [
          {
            "id": "1",
            "range": "1:1",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000001",
            "target": "1BgGZ9tcN4rm9KBzDn7KprQz87SZ26SAMH",
            "balance": "0.2482343",
            "solved": true
          },
          {
            "id": "2",
            "range": "2:3",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000003",
            "target": "1CUNEBjYrCn2y1SdiUMohaKUi4wpP326Lb",
            "balance": "0.00226546",
            "solved": true
          },
          {
            "id": "3",
            "range": "4:7",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000007",
            "target": "19ZewH8Kk1PDbSNdJ97FP4EiCjTRaZMZQA",
            "balance": "0.0031",
            "solved": true
          },
          {
            "id": "4",
            "range": "8:f",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000008",
            "target": "1EhqbyUMvvs7BfL8goY6qcPbD6YKfPqb7e",
            "balance": "0.00401601",
            "solved": true
          },
          {
            "id": "5",
            "range": "10:1f",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000015",
            "target": "1E6NuFjCi27W5zoXg8TRdcSRq84zJeBW3k",
            "balance": "0.005",
            "solved": true
          },
          {
            "id": "6",
            "range": "20:3f",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000031",
            "target": "1PitScNLyp2HCygzadCh7FveTnfmpPbfp8",
            "balance": "0.00618097",
            "solved": true
          },
          {
            "id": "7",
            "range": "40:7f",
            "privateKey": "000000000000000000000000000000000000000000000000000000000000004c",
            "target": "1McVt1vMtCC7yn5b9wgX1833yCcLXzueeC",
            "balance": "0.007",
            "solved": true
          },
          {
            "id": "8",
            "range": "80:ff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000000000e0",
            "target": "1M92tSqNmQLYw33fuBvjmeadirh1ysMBxK",
            "balance": "0.008",
            "solved": true
          },
          {
            "id": "9",
            "range": "100:1ff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000000001d3",
            "target": "1CQFwcjw1dwhtkVWBttNLDtqL7ivBonGPV",
            "balance": "0.018",
            "solved": true
          },
          {
            "id": "10",
            "range": "200:3ff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000202",
            "target": "1LeBZP5QCwwgXRtmVUvTVrraqPUokyLHqe",
            "balance": "0.01012795",
            "solved": true
          },
          {
            "id": "11",
            "range": "400:7ff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000483",
            "target": "1PgQVLmst3Z314JrQn5TNiys8Hc38TcXJu",
            "balance": "0.011",
            "solved": true
          },
          {
            "id": "12",
            "range": "800:fff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000000a7b",
            "target": "1DBaumZxUkM4qMQRt2LVWyFJq5kDtSZQot",
            "balance": "0.01200961",
            "solved": true
          },
          {
            "id": "13",
            "range": "1000:1fff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000001460",
            "target": "1Pie8JkxBT6MGPz9Nvi3fsPkr2D8q3GBc1",
            "balance": "0.013",
            "solved": true
          },
          {
            "id": "14",
            "range": "2000:3fff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000002930",
            "target": "1ErZWg5cFCe4Vw5BzgfzB74VNLaXEiEkhk",
            "balance": "0.014",
            "solved": true
          },
          {
            "id": "15",
            "range": "4000:7fff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000000068f3",
            "target": "1QCbW9HWnwQWiQqVo5exhAnmfqKRrCRsvW",
            "balance": "0.015",
            "solved": true
          },
          {
            "id": "16",
            "range": "8000:ffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000000000c936",
            "target": "1BDyrQ6WoF8VN3g9SAS1iKZcPzFfnDVieY",
            "balance": "0.01601",
            "solved": true
          },
          {
            "id": "17",
            "range": "10000:1ffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000000001764f",
            "target": "1HduPEXZRdG26SUT5Yk83mLkPyjnZuJ7Bm",
            "balance": "0.017",
            "solved": true
          },
          {
            "id": "18",
            "range": "20000:3ffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000000003080d",
            "target": "1GnNTmTVLZiqQfLbAdp9DVdicEnB5GoERE",
            "balance": "0.018",
            "solved": true
          },
          {
            "id": "19",
            "range": "40000:7ffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000000005749f",
            "target": "1NWmZRpHH4XSPwsW6dsS3nrNWfL1yrJj4w",
            "balance": "0.019",
            "solved": true
          },
          {
            "id": "20",
            "range": "80000:fffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000000d2c55",
            "target": "1HsMJxNiV7TLxmoF6uJNkydxPFDog4NQum",
            "balance": "0.02",
            "solved": true
          },
          {
            "id": "21",
            "range": "100000:1fffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000001ba534",
            "target": "14oFNXucftsHiUMY8uctg6N487riuyXs4h",
            "balance": "0.02201813",
            "solved": true
          },
          {
            "id": "22",
            "range": "200000:3fffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000002de40f",
            "target": "1CfZWK1QTQE3eS9qn61dQjV89KDjZzfNcv",
            "balance": "0.022",
            "solved": true
          },
          {
            "id": "23",
            "range": "400000:7fffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000556e52",
            "target": "1L2GM8eE7mJWLdo3HZS6su1832NX2txaac",
            "balance": "0.023",
            "solved": true
          },
          {
            "id": "24",
            "range": "800000:ffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000000dc2a04",
            "target": "1rSnXMr63jdCuegJFuidJqWxUPV7AtUf7",
            "balance": "0.024",
            "solved": true
          },
          {
            "id": "25",
            "range": "1000000:1ffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000001fa5ee5",
            "target": "15JhYXn6Mx3oF4Y7PcTAv2wVVAuCFFQNiP",
            "balance": "0.025",
            "solved": true
          },
          {
            "id": "26",
            "range": "2000000:3ffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000000340326e",
            "target": "1JVnST957hGztonaWK6FougdtjxzHzRMMg",
            "balance": "0.026",
            "solved": true
          },
          {
            "id": "27",
            "range": "4000000:7ffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000006ac3875",
            "target": "128z5d7nN7PkCuX5qoA4Ys6pmxUYnEy86k",
            "balance": "0.027",
            "solved": true
          },
          {
            "id": "28",
            "range": "8000000:fffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000000d916ce8",
            "target": "12jbtzBb54r97TCwW3G1gCFoumpckRAPdY",
            "balance": "0.028",
            "solved": true
          },
          {
            "id": "29",
            "range": "10000000:1fffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000000017e2551e",
            "target": "19EEC52krRUK1RkUAEZmQdjTyHT7Gp1TYT",
            "balance": "0.029",
            "solved": true
          },
          {
            "id": "30",
            "range": "20000000:3fffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000003d94cd64",
            "target": "1LHtnpd8nU5VHEMkG2TMYYNUjjLc992bps",
            "balance": "0.0303",
            "solved": true
          },
          {
            "id": "31",
            "range": "40000000:7fffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000007d4fe747",
            "target": "1LhE6sCTuGae42Axu1L1ZB7L96yi9irEBE",
            "balance": "0.031",
            "solved": true
          },
          {
            "id": "32",
            "range": "80000000:ffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000000b862a62e",
            "target": "1FRoHA9xewq7DjrZ1psWJVeTer8gHRqEvR",
            "balance": "0.032",
            "solved": true
          },
          {
            "id": "33",
            "range": "100000000:1ffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000001a96ca8d8",
            "target": "187swFMjz1G54ycVU56B7jZFHFTNVQFDiu",
            "balance": "0.033",
            "solved": true
          },
          {
            "id": "34",
            "range": "200000000:3ffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000034a65911d",
            "target": "1PWABE7oUahG2AFFQhhvViQovnCr4rEv7Q",
            "balance": "0.034",
            "solved": true
          },
          {
            "id": "35",
            "range": "400000000:7ffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000004aed21170",
            "target": "1PWCx5fovoEaoBowAvF5k91m2Xat9bMgwb",
            "balance": "0.035",
            "solved": true
          },
          {
            "id": "36",
            "range": "800000000:fffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000009de820a7c",
            "target": "1Be2UF9NLfyLFbtm3TCbmuocc9N1Kduci1",
            "balance": "0.036",
            "solved": true
          },
          {
            "id": "37",
            "range": "1000000000:1fffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000001757756a93",
            "target": "14iXhn8bGajVWegZHJ18vJLHhntcpL4dex",
            "balance": "0.037",
            "solved": true
          },
          {
            "id": "38",
            "range": "2000000000:3fffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000022382facd0",
            "target": "1HBtApAFA9B2YZw3G2YKSMCtb3dVnjuNe2",
            "balance": "0.038",
            "solved": true
          },
          {
            "id": "39",
            "range": "4000000000:7fffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000004b5f8303e9",
            "target": "122AJhKLEfkFBaGAd84pLp1kfE7xK3GdT8",
            "balance": "0.03901",
            "solved": true
          },
          {
            "id": "40",
            "range": "8000000000:ffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000000e9ae4933d6",
            "target": "1EeAxcprB2PpCnr34VfZdFrkUWuxyiNEFv",
            "balance": "0.04",
            "solved": true
          },
          {
            "id": "41",
            "range": "10000000000:1ffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000153869acc5b",
            "target": "1L5sU9qvJeuwQUdt4y1eiLmquFxKjtHr3E",
            "balance": "0.041",
            "solved": true
          },
          {
            "id": "42",
            "range": "20000000000:3ffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000002a221c58d8f",
            "target": "1E32GPWgDyeyQac4aJxm9HVoLrrEYPnM4N",
            "balance": "0.042",
            "solved": true
          },
          {
            "id": "43",
            "range": "40000000000:7ffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000006bd3b27c591",
            "target": "1PiFuqGpG8yGM5v6rNHWS3TjsG6awgEGA1",
            "balance": "0.043",
            "solved": true
          },
          {
            "id": "44",
            "range": "80000000000:fffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000000e02b35a358f",
            "target": "1CkR2uS7LmFwc3T2jV8C1BhWb5mQaoxedF",
            "balance": "0.044",
            "solved": true
          },
          {
            "id": "45",
            "range": "100000000000:1fffffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000122fca143c05",
            "target": "1NtiLNGegHWE3Mp9g2JPkgx6wUg4TW7bbk",
            "balance": "0.045",
            "solved": true
          },
          {
            "id": "46",
            "range": "200000000000:3fffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000002ec18388d544",
            "target": "1F3JRMWudBaj48EhwcHDdpeuy2jwACNxjP",
            "balance": "0.046",
            "solved": true
          },
          {
            "id": "47",
            "range": "400000000000:7fffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000006cd610b53cba",
            "target": "1Pd8VvT49sHKsmqrQiP61RsVwmXCZ6ay7Z",
            "balance": "0.0473496",
            "solved": true
          },
          {
            "id": "48",
            "range": "800000000000:ffffffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000000ade6d7ce3b9b",
            "target": "1DFYhaB2J9q1LLZJWKTnscPWos9VBqDHzv",
            "balance": "0.048",
            "solved": true
          },
          {
            "id": "49",
            "range": "1000000000000:1ffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000174176b015f4d",
            "target": "12CiUhYVTTH33w3SPUBqcpMoqnApAV4WCF",
            "balance": "0.049",
            "solved": true
          },
          {
            "id": "50",
            "range": "2000000000000:3ffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000022bd43c2e9354",
            "target": "1MEzite4ReNuWaL5Ds17ePKt2dCxWEofwk",
            "balance": "0.05000546",
            "solved": true
          },
          {
            "id": "51",
            "range": "4000000000000:7ffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000075070a1a009d4",
            "target": "1NpnQyZ7x24ud82b7WiRNvPm6N8bqGQnaS",
            "balance": "0.10171886",
            "solved": true
          },
          {
            "id": "52",
            "range": "8000000000000:fffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000000efae164cb9e3c",
            "target": "15z9c9sVpu6fwNiK7dMAFgMYSK4GqsGZim",
            "balance": "0.052",
            "solved": true
          },
          {
            "id": "53",
            "range": "10000000000000:1fffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000180788e47e326c",
            "target": "15K1YKJMiJ4fpesTVUcByoz334rHmknxmT",
            "balance": "0.53",
            "solved": true
          },
          {
            "id": "54",
            "range": "20000000000000:3fffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000000236fb6d5ad1f43",
            "target": "1KYUv7nSvXx4642TKeuC2SNdTk326uUpFy",
            "balance": "0.54002",
            "solved": true
          },
          {
            "id": "55",
            "range": "40000000000000:7fffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000006abe1f9b67e114",
            "target": "1LzhS3k3e9Ub8i2W1V8xQFdB8n2MYCHPCa",
            "balance": "0.60011515",
            "solved": true
          },
          {
            "id": "56",
            "range": "80000000000000:ffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000009d18b63ac4ffdf",
            "target": "17aPYR1m6pVAacXg1PTDDU7XafvK1dxvhi",
            "balance": "0.71343285",
            "solved": true
          },
          {
            "id": "57",
            "range": "100000000000000:1ffffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000001eb25c90795d61c",
            "target": "15c9mPGLku1HuW9LRtBf4jcHVpBUt8txKz",
            "balance": "0.57038752",
            "solved": true
          },
          {
            "id": "58",
            "range": "200000000000000:3ffffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000002c675b852189a21",
            "target": "1Dn8NF8qDyyfHMktmuoQLGyjWmZXgvosXf",
            "balance": "0.58",
            "solved": true
          },
          {
            "id": "59",
            "range": "400000000000000:7ffffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000007496cbb87cab44f",
            "target": "1HAX2n9Uruu9YDt4cqRgYcvtGvZj1rbUyt",
            "balance": "0.59041",
            "solved": true
          },
          {
            "id": "60",
            "range": "800000000000000:fffffffffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000000fc07a1825367bbe",
            "target": "1Kn5h2qpgw9mWE5jKpk8PP4qvvJ1QVy8su",
            "balance": "0.600015",
            "solved": true
          },
          {
            "id": "61",
            "range": "1000000000000000:1fffffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000000013c96a3742f64906",
            "target": "1AVJKwzs9AskraJLGHAZPiaZcrpDr1U6AB",
            "balance": "0.61000793",
            "solved": true
          },
          {
            "id": "62",
            "range": "2000000000000000:3fffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000363d541eb611abee",
            "target": "1Me6EfpwZK5kQziBwBfvLiHjaPGxCKLoJi",
            "balance": "0.620091",
            "solved": true
          },
          {
            "id": "63",
            "range": "4000000000000000:7fffffffffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000007cce5efdaccf6808",
            "target": "1NpYjtLira16LfGbGwZJ5JbDPh3ai9bjf4",
            "balance": "0.63004413",
            "solved": true
          },
          {
            "id": "64",
            "range": "8000000000000000:ffffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000000f7051f27b09112d4",
            "target": "16jY7qLJnxb7CHZyqBP8qca9d51gAjyXQN",
            "balance": "0.64040459",
            "solved": true
          },
          {
            "id": "65",
            "range": "10000000000000000:1ffffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000001a838b13505b26867",
            "target": "18ZMbwUFLMHoZBbfpCjUJQTCMCbktshgpe",
            "balance": "0.65015705",
            "solved": true
          },
          {
            "id": "66",
            "range": "20000000000000000:3ffffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000000000002832ed74f2b5e35ee",
            "target": "13zb1hQbWVsc2S7ZTZnP2G4undNNpdh5so",
            "balance": "6.60128491",
            "solved": true
          },
          {
            "id": "67",
            "range": "40000000000000000:7ffffffffffffffff",
            "privateKey": "",
            "target": "1BY8GQbnueYofwSuFAT3USAhGjPrkxDdW9",
            "balance": "6.70011296",
            "solved": false
          },
          {
            "id": "68",
            "range": "80000000000000000:fffffffffffffffff",
            "privateKey": "",
            "target": "1MVDYgVaSN6iKKEsbzRUAYFrYJadLYZvvZ",
            "balance": "6.80005914",
            "solved": false
          },
          {
            "id": "69",
            "range": "100000000000000000:1fffffffffffffffff",
            "privateKey": "",
            "target": "19vkiEajfhuZ8bs8Zu2jgmC6oqZbWqhxhG",
            "balance": "6.90013661",
            "solved": false
          },
          {
            "id": "70",
            "range": "200000000000000000:3fffffffffffffffff",
            "privateKey": "0000000000000000000000000000000000000000000000349b84b6431a6c4ef1",
            "target": "19YZECXj3SxEZMoUeJ1yiPsw8xANe7M7QR",
            "balance": "0.70071362",
            "solved": true
          },
          {
            "id": "71",
            "range": "400000000000000000:7fffffffffffffffff",
            "privateKey": "",
            "target": "1PWo3JeB9jrGwfHDNpdGK54CRas7fsVzXU",
            "balance": "7.1000437",
            "solved": false
          },
          {
            "id": "72",
            "range": "800000000000000000:ffffffffffffffffff",
            "privateKey": "",
            "target": "1JTK7s9YVYywfm5XUH7RNhHJH1LshCaRFR",
            "balance": "7.20004379",
            "solved": false
          },
          {
            "id": "73",
            "range": "1000000000000000000:1ffffffffffffffffff",
            "privateKey": "",
            "target": "12VVRNPi4SJqUTsp6FmqDqY5sGosDtysn4",
            "balance": "7.30004377",
            "solved": false
          },
          {
            "id": "74",
            "range": "2000000000000000000:3ffffffffffffffffff",
            "privateKey": "",
            "target": "1FWGcVDK3JGzCC3WtkYetULPszMaK2Jksv",
            "balance": "7.40004377",
            "solved": false
          },
          {
            "id": "75",
            "range": "4000000000000000000:7ffffffffffffffffff",
            "privateKey": "0000000000000000000000000000000000000000000004c5ce114686a1336e07",
            "target": "1J36UjUByGroXcCvmj13U6uwaVv9caEeAt",
            "balance": "0.75001",
            "solved": true
          },
          {
            "id": "76",
            "range": "8000000000000000000:fffffffffffffffffff",
            "privateKey": "",
            "target": "1DJh2eHFYQfACPmrvpyWc8MSTYKh7w9eRF",
            "balance": "7.6",
            "solved": false
          },
          {
            "id": "77",
            "range": "10000000000000000000:1fffffffffffffffffff",
            "privateKey": "",
            "target": "1Bxk4CQdqL9p22JEtDfdXMsng1XacifUtE",
            "balance": "7.70002426",
            "solved": false
          },
          {
            "id": "78",
            "range": "20000000000000000000:3fffffffffffffffffff",
            "privateKey": "",
            "target": "15qF6X51huDjqTmF9BJgxXdt1xcj46Jmhb",
            "balance": "7.8",
            "solved": false
          },
          {
            "id": "79",
            "range": "40000000000000000000:7fffffffffffffffffff",
            "privateKey": "",
            "target": "1ARk8HWJMn8js8tQmGUJeQHjSE7KRkn2t8",
            "balance": "7.9",
            "solved": false
          },
          {
            "id": "80",
            "range": "80000000000000000000:ffffffffffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000000ea1a5c66dcc11b5ad180",
            "target": "1BCf6rHUW6m3iH2ptsvnjgLruAiPQQepLe",
            "balance": "0.80001",
            "solved": true
          },
          {
            "id": "81",
            "range": "100000000000000000000:1ffffffffffffffffffff",
            "privateKey": "",
            "target": "15qsCm78whspNQFydGJQk5rexzxTQopnHZ",
            "balance": "8.1",
            "solved": false
          },
          {
            "id": "82",
            "range": "200000000000000000000:3ffffffffffffffffffff",
            "privateKey": "",
            "target": "13zYrYhhJxp6Ui1VV7pqa5WDhNWM45ARAC",
            "balance": "8.2",
            "solved": false
          },
          {
            "id": "83",
            "range": "400000000000000000000:7ffffffffffffffffffff",
            "privateKey": "",
            "target": "14MdEb4eFcT3MVG5sPFG4jGLuHJSnt1Dk2",
            "balance": "8.3",
            "solved": false
          },
          {
            "id": "84",
            "range": "800000000000000000000:fffffffffffffffffffff",
            "privateKey": "",
            "target": "1CMq3SvFcVEcpLMuuH8PUcNiqsK1oicG2D",
            "balance": "8.4",
            "solved": false
          },
          {
            "id": "85",
            "range": "1000000000000000000000:1fffffffffffffffffffff",
            "privateKey": "00000000000000000000000000000000000000000011720c4f018d51b8cebba8",
            "target": "1Kh22PvXERd2xpTQk3ur6pPEqFeckCJfAr",
            "balance": "0.85001",
            "solved": true
          },
          {
            "id": "86",
            "range": "2000000000000000000000:3fffffffffffffffffffff",
            "privateKey": "",
            "target": "1K3x5L6G57Y494fDqBfrojD28UJv4s5JcK",
            "balance": "8.6",
            "solved": false
          },
          {
            "id": "87",
            "range": "4000000000000000000000:7fffffffffffffffffffff",
            "privateKey": "",
            "target": "1PxH3K1Shdjb7gSEoTX7UPDZ6SH4qGPrvq",
            "balance": "8.7",
            "solved": false
          },
          {
            "id": "88",
            "range": "8000000000000000000000:ffffffffffffffffffffff",
            "privateKey": "",
            "target": "16AbnZjZZipwHMkYKBSfswGWKDmXHjEpSf",
            "balance": "8.8",
            "solved": false
          },
          {
            "id": "89",
            "range": "10000000000000000000000:1ffffffffffffffffffffff",
            "privateKey": "",
            "target": "19QciEHbGVNY4hrhfKXmcBBCrJSBZ6TaVt",
            "balance": "8.9",
            "solved": false
          },
          {
            "id": "90",
            "range": "20000000000000000000000:3ffffffffffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000002ce00bb2136a445c71e85bf",
            "target": "1L12FHH2FHjvTviyanuiFVfmzCy46RRATU",
            "balance": "0.90001774",
            "solved": true
          },
          {
            "id": "91",
            "range": "40000000000000000000000:7ffffffffffffffffffffff",
            "privateKey": "",
            "target": "1EzVHtmbN4fs4MiNk3ppEnKKhsmXYJ4s74",
            "balance": "9.1",
            "solved": false
          },
          {
            "id": "92",
            "range": "80000000000000000000000:fffffffffffffffffffffff",
            "privateKey": "",
            "target": "1AE8NzzgKE7Yhz7BWtAcAAxiFMbPo82NB5",
            "balance": "9.2",
            "solved": false
          },
          {
            "id": "93",
            "range": "100000000000000000000000:1fffffffffffffffffffffff",
            "privateKey": "",
            "target": "17Q7tuG2JwFFU9rXVj3uZqRtioH3mx2Jad",
            "balance": "9.3",
            "solved": false
          },
          {
            "id": "94",
            "range": "200000000000000000000000:3fffffffffffffffffffffff",
            "privateKey": "",
            "target": "1K6xGMUbs6ZTXBnhw1pippqwK6wjBWtNpL",
            "balance": "9.4",
            "solved": false
          },
          {
            "id": "95",
            "range": "400000000000000000000000:7fffffffffffffffffffffff",
            "privateKey": "0000000000000000000000000000000000000000527a792b183c7f64a0e8b1f4",
            "target": "19eVSDuizydXxhohGh8Ki9WY9KsHdSwoQC",
            "balance": "0.95001",
            "solved": true
          },
          {
            "id": "96",
            "range": "800000000000000000000000:ffffffffffffffffffffffff",
            "privateKey": "",
            "target": "15ANYzzCp5BFHcCnVFzXqyibpzgPLWaD8b",
            "balance": "9.6",
            "solved": false
          },
          {
            "id": "97",
            "range": "1000000000000000000000000:1ffffffffffffffffffffffff",
            "privateKey": "",
            "target": "18ywPwj39nGjqBrQJSzZVq2izR12MDpDr8",
            "balance": "9.7",
            "solved": false
          },
          {
            "id": "98",
            "range": "2000000000000000000000000:3ffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1CaBVPrwUxbQYYswu32w7Mj4HR4maNoJSX",
            "balance": "9.8",
            "solved": false
          },
          {
            "id": "99",
            "range": "4000000000000000000000000:7ffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1JWnE6p6UN7ZJBN7TtcbNDoRcjFtuDWoNL",
            "balance": "9.91257338",
            "solved": false
          },
          {
            "id": "100",
            "range": "8000000000000000000000000:fffffffffffffffffffffffff",
            "privateKey": "000000000000000000000000000000000000000af55fc59c335c8ec67ed24826",
            "target": "1KCgMv8fo2TPBpddVi9jqmMmcne9uSNJ5F",
            "balance": "1.00047053",
            "solved": true
          },
          {
            "id": "101",
            "range": "10000000000000000000000000:1fffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1CKCVdbDJasYmhswB6HKZHEAnNaDpK7W4n",
            "balance": "10.1",
            "solved": false
          },
          {
            "id": "102",
            "range": "20000000000000000000000000:3fffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1PXv28YxmYMaB8zxrKeZBW8dt2HK7RkRPX",
            "balance": "10.2",
            "solved": false
          },
          {
            "id": "103",
            "range": "40000000000000000000000000:7fffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1AcAmB6jmtU6AiEcXkmiNE9TNVPsj9DULf",
            "balance": "10.3",
            "solved": false
          },
          {
            "id": "104",
            "range": "80000000000000000000000000:ffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1EQJvpsmhazYCcKX5Au6AZmZKRnzarMVZu",
            "balance": "10.400016",
            "solved": false
          },
          {
            "id": "105",
            "range": "100000000000000000000000000:1ffffffffffffffffffffffffff",
            "privateKey": "000000000000000000000000000000000000016f14fc2054cd87ee6396b33df3",
            "target": "1CMjscKB3QW7SDyQ4c3C3DEUHiHRhiZVib",
            "balance": "1.05001",
            "solved": true
          },
          {
            "id": "106",
            "range": "200000000000000000000000000:3ffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "18KsfuHuzQaBTNLASyj15hy4LuqPUo1FNB",
            "balance": "10.6",
            "solved": false
          },
          {
            "id": "107",
            "range": "400000000000000000000000000:7ffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "15EJFC5ZTs9nhsdvSUeBXjLAuYq3SWaxTc",
            "balance": "10.7",
            "solved": false
          },
          {
            "id": "108",
            "range": "800000000000000000000000000:fffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1HB1iKUqeffnVsvQsbpC6dNi1XKbyNuqao",
            "balance": "10.8",
            "solved": false
          },
          {
            "id": "109",
            "range": "1000000000000000000000000000:1fffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1GvgAXVCbA8FBjXfWiAms4ytFeJcKsoyhL",
            "balance": "10.9",
            "solved": false
          },
          {
            "id": "110",
            "range": "2000000000000000000000000000:3fffffffffffffffffffffffffff",
            "privateKey": "00000000000000000000000000000000000035c0d7234df7deb0f20cf7062444",
            "target": "12JzYkkN76xkwvcPT6AWKZtGX6w2LAgsJg",
            "balance": "1.10035333",
            "solved": true
          },
          {
            "id": "111",
            "range": "4000000000000000000000000000:7fffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1824ZJQ7nKJ9QFTRBqn7z7dHV5EGpzUpH3",
            "balance": "11.1001",
            "solved": false
          },
          {
            "id": "112",
            "range": "8000000000000000000000000000:ffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "18A7NA9FTsnJxWgkoFfPAFbQzuQxpRtCos",
            "balance": "11.2",
            "solved": false
          },
          {
            "id": "113",
            "range": "10000000000000000000000000000:1ffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1NeGn21dUDDeqFQ63xb2SpgUuXuBLA4WT4",
            "balance": "11.3",
            "solved": false
          },
          {
            "id": "114",
            "range": "20000000000000000000000000000:3ffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "174SNxfqpdMGYy5YQcfLbSTK3MRNZEePoy",
            "balance": "11.4",
            "solved": false
          },
          {
            "id": "115",
            "range": "40000000000000000000000000000:7ffffffffffffffffffffffffffff",
            "privateKey": "0000000000000000000000000000000000060f4d11574f5deee49961d9609ac6",
            "target": "1NLbHuJebVwUZ1XqDjsAyfTRUPwDQbemfv",
            "balance": "1.15014",
            "solved": true
          },
          {
            "id": "116",
            "range": "80000000000000000000000000000:fffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1MnJ6hdhvK37VLmqcdEwqC3iFxyWH2PHUV",
            "balance": "11.6",
            "solved": false
          },
          {
            "id": "117",
            "range": "100000000000000000000000000000:1fffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1KNRfGWw7Q9Rmwsc6NT5zsdvEb9M2Wkj5Z",
            "balance": "11.7",
            "solved": false
          },
          {
            "id": "118",
            "range": "200000000000000000000000000000:3fffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1PJZPzvGX19a7twf5HyD2VvNiPdHLzm9F6",
            "balance": "11.80000661",
            "solved": false
          },
          {
            "id": "119",
            "range": "400000000000000000000000000000:7fffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1GuBBhf61rnvRe4K8zu8vdQB3kHzwFqSy7",
            "balance": "11.9",
            "solved": false
          },
          {
            "id": "120",
            "range": "800000000000000000000000000000:ffffffffffffffffffffffffffffff",
            "privateKey": "0000000000000000000000000000000000b10f22572c497a836ea187f2e1fc23",
            "target": "17s2b9ksz5y7abUm92cHwG8jEPCzK3dLnT",
            "balance": "1.20001",
            "solved": true
          },
          {
            "id": "121",
            "range": "1000000000000000000000000000000:1ffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1GDSuiThEV64c166LUFC9uDcVdGjqkxKyh",
            "balance": "12.1",
            "solved": false
          },
          {
            "id": "122",
            "range": "2000000000000000000000000000000:3ffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1Me3ASYt5JCTAK2XaC32RMeH34PdprrfDx",
            "balance": "12.2",
            "solved": false
          },
          {
            "id": "123",
            "range": "4000000000000000000000000000000:7ffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1CdufMQL892A69KXgv6UNBD17ywWqYpKut",
            "balance": "12.3",
            "solved": false
          },
          {
            "id": "124",
            "range": "8000000000000000000000000000000:fffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1BkkGsX9ZM6iwL3zbqs7HWBV7SvosR6m8N",
            "balance": "12.4",
            "solved": false
          },
          {
            "id": "125",
            "range": "10000000000000000000000000000000:1fffffffffffffffffffffffffffffff",
            "privateKey": " 0233709eb11e0d4439a729f21c2c443dedb727528229713f0065721ba8fa46f00e",
            "target": "1PXAyUB8ZoH3WD8n5zoAthYjN15yN5CVq5",
            "balance": "12.50004706",
            "solved": false
          },
          {
            "id": "126",
            "range": "20000000000000000000000000000000:3fffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1AWCLZAjKbV1P7AHvaPNCKiB7ZWVDMxFiz",
            "balance": "12.6",
            "solved": false
          },
          {
            "id": "127",
            "range": "40000000000000000000000000000000:7fffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1G6EFyBRU86sThN3SSt3GrHu1sA7w7nzi4",
            "balance": "12.7",
            "solved": false
          },
          {
            "id": "128",
            "range": "80000000000000000000000000000000:ffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1MZ2L1gFrCtkkn6DnTT2e4PFUTHw9gNwaj",
            "balance": "12.8",
            "solved": false
          },
          {
            "id": "129",
            "range": "100000000000000000000000000000000:1ffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1Hz3uv3nNZzBVMXLGadCucgjiCs5W9vaGz",
            "balance": "12.9",
            "solved": false
          },
          {
            "id": "130",
            "range": "200000000000000000000000000000000:3ffffffffffffffffffffffffffffffff",
            "privateKey": " 03633cbe3ec02b9401c5effa144c5b4d22f87940259634858fc7e59b1c09937852",
            "target": "1Fo65aKq8s8iquMt6weF1rku1moWVEd5Ua",
            "balance": "13.00011312",
            "solved": false
          },
          {
            "id": "131",
            "range": "400000000000000000000000000000000:7ffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "16zRPnT8znwq42q7XeMkZUhb1bKqgRogyy",
            "balance": "13.1",
            "solved": false
          },
          {
            "id": "132",
            "range": "800000000000000000000000000000000:fffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1KrU4dHE5WrW8rhWDsTRjR21r8t3dsrS3R",
            "balance": "13.2",
            "solved": false
          },
          {
            "id": "133",
            "range": "1000000000000000000000000000000000:1fffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "17uDfp5r4n441xkgLFmhNoSW1KWp6xVLD",
            "balance": "13.3",
            "solved": false
          },
          {
            "id": "134",
            "range": "2000000000000000000000000000000000:3fffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "13A3JrvXmvg5w9XGvyyR4JEJqiLz8ZySY3",
            "balance": "13.4",
            "solved": false
          },
          {
            "id": "135",
            "range": "4000000000000000000000000000000000:7fffffffffffffffffffffffffffffffff",
            "privateKey": " 02145d2611c823a396ef6712ce0f712f09b9b4f3135e3e0aa3230fb9b6d08d1e16",
            "target": "16RGFo6hjq9ym6Pj7N5H7L1NR1rVPJyw2v",
            "balance": "13.50003808",
            "solved": false
          },
          {
            "id": "136",
            "range": "8000000000000000000000000000000000:ffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1UDHPdovvR985NrWSkdWQDEQ1xuRiTALq",
            "balance": "13.6",
            "solved": false
          },
          {
            "id": "137",
            "range": "10000000000000000000000000000000000:1ffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "15nf31J46iLuK1ZkTnqHo7WgN5cARFK3RA",
            "balance": "13.7",
            "solved": false
          },
          {
            "id": "138",
            "range": "20000000000000000000000000000000000:3ffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1Ab4vzG6wEQBDNQM1B2bvUz4fqXXdFk2WT",
            "balance": "13.8",
            "solved": false
          },
          {
            "id": "139",
            "range": "40000000000000000000000000000000000:7ffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1Fz63c775VV9fNyj25d9Xfw3YHE6sKCxbt",
            "balance": "13.9",
            "solved": false
          },
          {
            "id": "140",
            "range": "80000000000000000000000000000000000:fffffffffffffffffffffffffffffffffff",
            "privateKey": " 031f6a332d3c5c4f2de2378c012f429cd109ba07d69690c6c701b6bb87860d6640",
            "target": "1QKBaU6WAeycb3DbKbLBkX7vJiaS8r42Xo",
            "balance": "14.000026",
            "solved": false
          },
          {
            "id": "141",
            "range": "100000000000000000000000000000000000:1fffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1CD91Vm97mLQvXhrnoMChhJx4TP9MaQkJo",
            "balance": "14.1",
            "solved": false
          },
          {
            "id": "142",
            "range": "200000000000000000000000000000000000:3fffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "15MnK2jXPqTMURX4xC3h4mAZxyCcaWWEDD",
            "balance": "14.2",
            "solved": false
          },
          {
            "id": "143",
            "range": "400000000000000000000000000000000000:7fffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "13N66gCzWWHEZBxhVxG18P8wyjEWF9Yoi1",
            "balance": "14.3",
            "solved": false
          },
          {
            "id": "144",
            "range": "800000000000000000000000000000000000:ffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1NevxKDYuDcCh1ZMMi6ftmWwGrZKC6j7Ux",
            "balance": "14.4",
            "solved": false
          },
          {
            "id": "145",
            "range": "1000000000000000000000000000000000000:1ffffffffffffffffffffffffffffffffffff",
            "privateKey": " 03afdda497369e219a2c1c369954a930e4d3740968e5e4352475bcffce3140dae5",
            "target": "19GpszRNUej5yYqxXoLnbZWKew3KdVLkXg",
            "balance": "14.500016",
            "solved": false
          },
          {
            "id": "146",
            "range": "2000000000000000000000000000000000000:3ffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1M7ipcdYHey2Y5RZM34MBbpugghmjaV89P",
            "balance": "14.6",
            "solved": false
          },
          {
            "id": "147",
            "range": "4000000000000000000000000000000000000:7ffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "18aNhurEAJsw6BAgtANpexk5ob1aGTwSeL",
            "balance": "14.7",
            "solved": false
          },
          {
            "id": "148",
            "range": "8000000000000000000000000000000000000:fffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1FwZXt6EpRT7Fkndzv6K4b4DFoT4trbMrV",
            "balance": "14.8",
            "solved": false
          },
          {
            "id": "149",
            "range": "10000000000000000000000000000000000000:1fffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1CXvTzR6qv8wJ7eprzUKeWxyGcHwDYP1i2",
            "balance": "14.90001",
            "solved": false
          },
          {
            "id": "150",
            "range": "20000000000000000000000000000000000000:3fffffffffffffffffffffffffffffffffffff",
            "privateKey": " 03137807790ea7dc6e97901c2bc87411f45ed74a5629315c4e4b03a0a102250c49",
            "target": "1MUJSJYtGPVGkBCTqGspnxyHahpt5Te8jy",
            "balance": "15.000026",
            "solved": false
          },
          {
            "id": "151",
            "range": "40000000000000000000000000000000000000:7fffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "13Q84TNNvgcL3HJiqQPvyBb9m4hxjS3jkV",
            "balance": "15.10001",
            "solved": false
          },
          {
            "id": "152",
            "range": "80000000000000000000000000000000000000:ffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1LuUHyrQr8PKSvbcY1v1PiuGuqFjWpDumN",
            "balance": "15.20001",
            "solved": false
          },
          {
            "id": "153",
            "range": "100000000000000000000000000000000000000:1ffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "18192XpzzdDi2K11QVHR7td2HcPS6Qs5vg",
            "balance": "15.30001",
            "solved": false
          },
          {
            "id": "154",
            "range": "200000000000000000000000000000000000000:3ffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1NgVmsCCJaKLzGyKLFJfVequnFW9ZvnMLN",
            "balance": "15.40001",
            "solved": false
          },
          {
            "id": "155",
            "range": "400000000000000000000000000000000000000:7ffffffffffffffffffffffffffffffffffffff",
            "privateKey": " 035cd1854cae45391ca4ec428cc7e6c7d9984424b954209a8eea197b9e364c05f6",
            "target": "1AoeP37TmHdFh8uN72fu9AqgtLrUwcv2wJ",
            "balance": "15.500026",
            "solved": false
          },
          {
            "id": "156",
            "range": "800000000000000000000000000000000000000:fffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "1FTpAbQa4h8trvhQXjXnmNhqdiGBd1oraE",
            "balance": "15.60001",
            "solved": false
          },
          {
            "id": "157",
            "range": "1000000000000000000000000000000000000000:1fffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "14JHoRAdmJg3XR4RjMDh6Wed6ft6hzbQe9",
            "balance": "15.70001",
            "solved": false
          },
          {
            "id": "158",
            "range": "2000000000000000000000000000000000000000:3fffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "19z6waranEf8CcP8FqNgdwUe1QRxvUNKBG",
            "balance": "15.80001",
            "solved": false
          },
          {
            "id": "159",
            "range": "4000000000000000000000000000000000000000:7fffffffffffffffffffffffffffffffffffffff",
            "privateKey": "",
            "target": "14u4nA5sugaswb6SZgn5av2vuChdMnD9E5",
            "balance": "15.900026",
            "solved": false
          },
          {
            "id": "160",
            "range": "8000000000000000000000000000000000000000:ffffffffffffffffffffffffffffffffffffffff",
            "privateKey": " 02e0a8b039282faf6fe0fd769cfbc4b6b4cf8758ba68220eac420e32b91ddfa673",
            "target": "1NBC8uXJy1GiJ6drkiZa1WuKn51ps7EPTv",
            "balance": "16.00020082",
            "solved": false
          }
        ]
      },
      itemId: 67,
      threads: 1,
      status: false,
      total: 0,
      lastKey: '',
      workers: [],
      speeds: [],
      solved: []
    }
  },
  mounted() {
    const config = this.getConfig();
    this.solved = this.getResults();
    this.itemId = config.itemId;
    this.threads = config.threads;
  },
  methods: {
    getConfig() {
      const results = localStorage.getItem('btc_puzzle_config') || '';
      try {
        return JSON.parse(results);
      } catch {
        return { itemId: 67, threads: 1 }
      }
    },
    setConfig() {
      localStorage.setItem('btc_puzzle_config', JSON.stringify({ itemId: this.itemId, threads: this.threads }));
    },
    getResults(){
      const results = localStorage.getItem('btc_puzzle_results') || '[]';
      try {
        return JSON.parse(results);
      } catch {
        return [];
      }
    },
    setResults(payload) {
      const results = this.getResults();
      const index = results.findIndex(o => o.id == payload.id);
      const item = { id: payload.id, target: payload.address, privateKey: payload.privateKey, totalScanned: this.total };
      if (index != -1) {
        results[index] = item;
      } else {
        results.push(item);
      }
      this.solved = results;
      localStorage.setItem('btc_puzzle_results', JSON.stringify(results));
    },
    splitRange(startHex, endHex, threads) {
      const start = parseInt(startHex, 16); // Convert to decimal
      const end = parseInt(endHex, 16);     // Convert to decimal

      if (start >= end || threads < 1) {
        throw new Error("Invalid range or thread count");
      }

      const total = end - start + 1; // Total range (inclusive)
      const chunkSize = Math.ceil(total / threads); // Size of each chunk

      const ranges = [];
      for (let i = 0; i < threads; i++) {
        const rangeStart = start + i * chunkSize;
        const rangeEnd = Math.min(rangeStart + chunkSize - 1, end);

        if (rangeStart > end) break; // Stop if range exceeds the end

        ranges.push(`${rangeStart.toString(16)}:${rangeEnd.toString(16)}`);
      }

      return ranges;
    },
    onStart() {
      this.status = true;
      const [start, end] = this.item.range.split(':');
      const range = this.splitRange(`0x${start}`, `0x${end}`, this.threads);
      for (let index = 0; index < this.threads; index++) {
        const worker = new Worker(new URL('../workers/worker.js', import.meta.url), { type: 'module' });
        const [wstart, wend] = range[index].split(':');
        worker.onmessage = (e) => {
          const { type, payload } = e.data;
          switch (type) {
            case 'hashrate':
              this.speeds[payload.thr_id] = payload.hashrate;
              this.total += payload.hashrate;
              this.lastKey = payload.privateKey;
              break;
            case 'success':
              message.success(`Puzzle #${this.item.id} is solved!`)
              this.setResults(payload);
              this.onStop();
              break;
            default:
              break;
          }
        }

        worker.postMessage({ type: 'start', payload: { id: this.item.id, thr_id: index, start: wstart, end: wend, target: this.item.target } });
        this.workers.push(worker);
        this.speeds.push(0);
      }
      this.setConfig();
    },
    onStop() {
      this.status = false;
      this.workers.forEach((w) => {
        w.postMessage({ type: 'stop' });
        w.terminate();
      });
      this.workers = [];
      this.speeds = [];
      this.total = 0;
      this.lastKey = '';
    }
  }
};
</script>
<style scoped>
.page-header {
  text-align: center;
}

.title {
  color: #fff;
  margin-bottom: 0;
  line-height: 1.5;
  letter-spacing: 1px;
}

.mt-3 {
  margin-top: 1rem;
}

.desc {
  font-size: 11px;
  color: rgb(255, 221, 0);
  letter-spacing: 1px;
  font-weight: 100;
}

.body {
  padding: 1rem 0;
}

.card-status {
  background: #222;
  border: none;
}

.card-status span {
  font-size: 12px;
  color: #939393;
  letter-spacing: 1px;
}

.card-status p {
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
}

.worker-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.start-button {
  background-color: #f7931a;
  color: #000;
  border-color: #f7931a;
}

.start-button:hover {
  color: #f7931a;
  background-color: #fff;
}

.info-table {
  margin-top: 1rem;
  width: 100%;
}

.info-table td {
  padding: 6px 10px;
  color: #fff;
  border-bottom: 1px solid #333;
  letter-spacing: 1px;
  white-space: pre-line;
  word-break: break-word;
}

.info-table tr td:first-child {
  padding-left: 0;
  color: #f7931a;
  white-space: nowrap;
}

.info {
  color: chartreuse !important;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
}

.result-table th {
  background-color: #f7931a;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  text-align: left;
}

.result-table td {
  padding: 5px 10px;
  border-bottom: 1px solid #333;
  font-size: 14px;
  color: #fff;
  white-space: pre-line;
  word-break: break-word;
}
.mb-1 {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .worker-form {
    flex-direction: column; 
  }

  .worker-form .ant-select,
  .worker-form .ant-input-number,
  .worker-form .ant-btn {
    width: 100% !important;
  }
}
</style>

<style>
.ant-select-disabled .ant-select-selector,
.ant-input-number-disabled {
  color: #fff;
  background-color: #e5e5e5 !important;
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-disabled .ant-input-number-input {
  color: rgba(0, 0, 0, 0.25);
}
</style>