import { API_BASE_URL } from "@/constants/config";
import axios from "axios";

const client = axios.create({
  baseURL: API_BASE_URL,
  timeout: 100000,
});

export const getCoinInfo = async (id, params) => {
  return await client.get(`/coin-info/${id}`, { params }).then((res) => res.data.result).catch(() => null);
}

export const getCoinInfos = async (data) => {
  return await client.post(`/coin-info`, { coins: data }).then((res) => res.data.result).catch(() => []);
}